export function getDefaultCheckData (data, type) {
  return {
    appraisalYearDetailId: data && data.id ? data.id : null,
    type: type,
    content: '',
    status: 'draw'
  }
}

export function parseData (data) {
  const formData = data ? JSON.parse(JSON.stringify(data)) : {
    checkDatas: []
  }
  formData.birth = (formData.birth || '').substr(0, 10)
  const leadCheckData = formData.checkDatas.find(v => v.type === 'lead') || getDefaultCheckData(data, 'lead')
  const orgCheckData = formData.checkDatas.find(v => v.type === 'org') || getDefaultCheckData(data, 'org')
  const myCheckData = formData.checkDatas.find(v => v.type === 'my') || getDefaultCheckData(data, 'my')
  const otherCheckData = formData.checkDatas.find(v => v.type === 'other') || getDefaultCheckData(data, 'other')
  return {
    formData, leadCheckData, orgCheckData, myCheckData, otherCheckData
  }
}

export function renderItem (year, org, item) {
  const { leadCheckData, orgCheckData, myCheckData, otherCheckData } = parseData(item)
  return '<div class="year-evaluation">'
    + '<h1>安徽省事业单位工作人员年度考核登记表</h1>'
    + '<h2>（' + year + '年度）</h2>'
    + '<p>单位：' + org + '</p>'
    + '<table>'
      + '<tr>'
        + '<td class="w299mm">姓&nbsp;&nbsp;&nbsp;&nbsp;名</td>'
        + '<td>' + item.workerName + '</td>'
        + '<td class="w299mm">性&nbsp;&nbsp;&nbsp;&nbsp;别</td>'
        + '<td>' + item.sex + '</td>'
        + '<td class="w299mm">出生年月</td>'
        + '<td>' + (item.birth || '').substr(0, 10) + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td class="w299mm">民&nbsp;&nbsp;&nbsp;&nbsp;族</td>'
        + '<td>' + (item.clan || '') + '</td>'
        + '<td class="w299mm">政治面貌</td>'
        + '<td>' + (item.politicalStatus || '') + '</td>'
        + '<td class="w299mm">文化程度</td>'
        + '<td>' + (item.education || '') + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td class="w299mm">管理岗位职务</td>'
        + '<td>' + (item.glgzw || '') + '</td>'
        + '<td class="w299mm">专技岗位职务</td>'
        + '<td>' + (item.zjgzw || '') + '</td>'
        + '<td class="w299mm">工勤岗位等级</td>'
        + '<td>' + (item.gqgwdj || '') + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td colspan="6" style="height: 18cm;" class="text">'
          + '<br />'
          + '<br />'
          + '<div style="text-align: center;">本人总结</div>'
          + '<div>' + (item.content || '') + '</div>'
        + '</td>'
      + '</tr>'
    + '</table>'
    + '<div style="page-break-after:always"></div>'
    + '<table>'
      + '<tr>'
        + '<td class="w174mm">主管领<br/>导评语<br/>及考核<br/>等次建<br/>议</td>'
        + '<td class="text">'
          + '<div class="content">'
            + '<div class="value"></div>'
            + '<div class="right">（签章）'
              + '<div class="sign-place"></div>年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日'
            + '</div>'
          + '</div>'
        + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td class="w174mm">考&nbsp;&nbsp;&nbsp;审<br />核&nbsp;&nbsp;&nbsp;核<br />单&nbsp;&nbsp;&nbsp;意<br />位&nbsp;&nbsp;&nbsp;见</td>'
        + '<td class="text">'
          + '<div class="content">'
            + '<div class="value">' + (leadCheckData.content || '') + '</div>'
            + '<div class="right">（签章）'
              + '<div class="sign-place"></div>年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日'
            + '</div>'
          + '</div>'
        + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td class="w174mm">主<br />管<br />部<br />门<br />意<br />见</td>'
        + '<td class="text">'
          + '<div class="content">'
            + '<div class="value">' + (orgCheckData.content || '') + '</div>'
            + '<div class="right">（签章）'
              + '<div class="sign-place"></div>年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日'
            + '</div>'
          + '</div>'
        + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td class="w174mm">个人<br />意见</td>'
        + '<td class="text">'
          + '<div class="content">'
            + '<div class="value">' + (myCheckData.content || '') + '</div>'
            + '<div class="right">（签章）'
              + '<div class="sign-place"></div>年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日'
            + '</div>'
          + '</div>'
        + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td class="w174mm">未确定<br />等次或<br />不参加<br />考核情<br />况说明</td>'
        + '<td class="text">'
          + '<div class="content">'
            + '<div class="value">' + (otherCheckData.content || '') + '</div>'
            + '<div class="right">（签章）'
              + '<div class="sign-place"></div>年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日'
            + '</div>'
          + '</div>'
        + '</td>'
      + '</tr>'
      + '<tr>'
        + '<td colspan="2">'
          + '<div class="content">'
            + '<div class="value"></div>'
            + '<div class="right">人社部门（盖章）：'
              + '<div class="sign-place"></div>'
              + '<div class="sign-place"></div>'
            + '</div>'
          + '</div>'
        + '</td>'
      + '</tr>'
    + '</table>'
    + '<p>本表正反面印制，填报后及时存入个人档案。</p>'
  + '</div>'
}